.cdc-web {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 100vh;
  position: relative;
  width: 100vw;
  box-sizing: border-box;
  padding: 10px;
}

.cdc-web .container {
  background-color: #ffffff;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.cdc-web .overlap-group {
  height: 100%;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.cdc-web .fondocdc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cdc-web .LOGO-CLINICA {
  position: absolute;
  top: 40%;
  left: 19%;
  width: 21%;
  height: auto;
  object-fit: cover;
}

.cdc-web .text-wrapper {
  position: absolute;
  top: 51%;
  left: 35%;
  width: 66%;
  font-family: "Inter-Bold", Helvetica;
  font-size: 5vw;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  color: #1c517a;
}

.cdc-web .div {
  position: absolute;
  top: 64%;
  left: 49%;
  width: 30%;
  font-family: "Syne-Bold", Helvetica;
  font-size: 4vw;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0;
  text-align: center;
  text-decoration: underline;
  color: #ffffff;
}

.cdc-web .text-wrapper-2 {
  position: absolute;
  top: 73%;
  left: 55%;
  width: 47%;
  font-family: "Syne-SemiBold", Helvetica;
  font-size: 4vw;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0;
  text-align: center;
  color: #ffffff;
}

.cdc-web .text-wrapper-3 {
  position: absolute;
  top: 82%;
  left: 55%;
  width: 47%;
  font-family: "Syne-SemiBold", Helvetica;
  font-size: 4vw;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0;
  text-align: center;
  color: #ffffff;
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
  .cdc-web .text-wrapper,
  .cdc-web .div,
  .cdc-web .text-wrapper-2,
  .cdc-web .text-wrapper-3 {
    font-size: 8vw;
  }

  .cdc-web .LOGO-CLINICA {
    top: 30%;
    left: 10%;
    width: 35%;
  }
}
